import React from "react";

const HowMadeComponents = () => {
    return(
        <>
           
            <iframe width="560" height="315" src="https://www.youtube.com/embed/3-HXJYTt3kM?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </>
    )
}

export default HowMadeComponents