import React from 'react';
import HowMadeComponents from '../components/HowMadeComponents';

const ClickShare = () =>  { 

    return(
        <> 
        <HowMadeComponents />
        </>
    )
}

export default ClickShare;