import React, { Component } from 'react';
import { Button, Form, Input, TextArea, Message, Select, Segment, Container, Checkbox } from 'semantic-ui-react';
import { Jumbotron, Col, Row, Modal } from 'react-bootstrap';
import agent from '../../../axios/agent';
import {  Days, Staj, Months, Years, StajEgitim } from '../constantArrays';
import emailjs from 'emailjs-com';


class StajBasvuruFormu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            topic: '',
            link: '',
            onFormGoing: true,
            role: '',
            date: new Date(),
            sendActivity: true,
            martialStatus: '',
            estatusOneStatus: '',
            schoolNameOneStatus: '',
            departmentNameOneStatus: '',
            estartDateOneStatus: '',
            esFinishDateOneStatus: '',
            birthdayStatus: '',
            birthmounthStatus: '',
            birthyearStatus: '',
            basVurPozStatus: '',
            errorArrayList: [],
            data: [],
            checkSent: false,
            isOpen: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCheck = this.handleCheck.bind(this);
    }

    componentDidMount() {
        agent.HrPozitions.list()
            .then(response => {
                this.setState({
                    data: response.data,
                })
            })
    }
    handleSubmit(e) {
        e.preventDefault()
        const { basVurPoz, nameSurname, phoneNumber, ePosta, addres,
            birthday, birthmounth, birthyear, estatusOne, schoolNameOne, departmentNameOne,
            estartDateOne, esFinishDateOne, jStartTime, date, checkSent, someinfo
        } = this.state
        if (!(basVurPoz) || !(schoolNameOne) || !(estartDateOne) || !(esFinishDateOne)
            || !(birthday) || !(birthmounth) || !(birthyear) ) {
            this.setState({
                sendActivity: false,
            })
            if (!basVurPoz) {
                this.setState({
                    basVurPozStatus: "Staj Türü Kısmı Bırakılamaz",
                })
                this.state.errorArrayList.push(this.state.basVurPozStatus)
            } else {
                this.state.errorArrayList.splice(1)
            }
            if (!schoolNameOne) {
                this.setState({
                    schoolNameOneStatus: "Okul Adı Kısmı Boş Bırakılamaz",
                })
                this.state.errorArrayList.push(this.state.schoolNameOneStatus)
            } else {
                this.state.errorArrayList.splice(2)
            }
            if (!departmentNameOne) {
                this.setState({
                    departmentNameOneStatus: "Bölüm Adı Kısmı Boş Bırakılamaz",
                })
                this.state.errorArrayList.push(this.state.departmentNameOneStatus)
            } else {
                this.state.errorArrayList.splice(3)
            }
            if (!estartDateOne) {
                this.setState({
                    estartDateOneStatus: "Eğitim Durumu Başlama Tarihi Boş Bırakılamaz",
                })
                this.state.errorArrayList.push(this.state.estartDateOneStatus)
            } else {
                this.state.errorArrayList.splice(4)
            }
            if (!esFinishDateOne) {
                this.setState({
                    esFinishDateOneStatus: "Eğitim Durumu Bitiş Tarihi Boş Bırakılamaz",
                })
                this.state.errorArrayList.push(this.state.esFinishDateOneStatus)
            } else {
                this.state.errorArrayList.splice(5)
            } if (!birthday) {
                this.setState({
                    birthdayStatus: "Doğum Günü Gün Seçeneği Boş Bırakılamaz",
                })
                this.state.errorArrayList.push(this.state.birthdayStatus)
            } else {
                this.state.errorArrayList.splice(6)
            } if (!birthmounth) {
                this.setState({
                    birthmounthStatus: "Doğum Günü Ay Seçeneği Tarihi Boş Bırakılamaz",
                })
                this.state.errorArrayList.push(this.state.birthmounthStatus)
            } else {
                this.state.errorArrayList.splice(7)
            } if (!birthyear) {
                this.setState({
                    birthyearStatus: "Doğum Günü Yıl Seçeneği Tarihi Boş Bırakılamaz",
                })
                this.state.errorArrayList.push(this.state.birthyearStatus)
            } else {
                this.state.errorArrayList.splice(8)
            }     
            
            console.log(this.state.errorArrayList)

        } else {
            this.setState({
                sendActivity: true,
            })
            let form = {
                CreationDate: date,
                PozitionFor: basVurPoz,
                NameSurname: nameSurname,
                TelNo: phoneNumber,
                Epost: ePosta,
                Address: addres,
                Birthday: birthday,
                BirthMounth: birthmounth,
                BirthYear: birthyear,
                EstatusOne: estatusOne,
                EschoolOne: schoolNameOne,
                EdepartmentOne: departmentNameOne,
                EstartDateOne: estartDateOne,
                EFinishDateOne: esFinishDateOne,
                JstarTime: jStartTime,
                AddingSomeInfo: someinfo,
                KvkkConfirm: checkSent,
            }


            let templateParams = {
                Request_Pozition: basVurPoz,
                Name_Surname: nameSurname,
                Tel_No: phoneNumber,
            }
            emailjs.send(
                'gmail_service',
                'template_gjBh8dYZ',
                templateParams,
                'user_sYzG1ti0KxwG2LJiHJmYg'
            )

            agent.HRApplication.create(form)
                .then(response => {
                    console.log(response)
                    if (response.status === 200)
                        this.resetForm()
                })
        }
    }
    handleCheck() {
        if (this.state.checkSent === true)
            this.setState({
                checkSent: false,
            });
        if (this.state.checkSent === false) {
            this.setState({
                checkSent: true,
            });
        }
        console.log(this.state.checkSent);
    }
    resetForm() {
        this.setState({
            Request_Pozition: '',
            Name_Surname: '',
            Tel_No: '',
            onFormGoing: false,
        })
    }
    handleChange = (param, e) => {
        this.setState({
            [param]: e.target.value,
        })
        //    console.log(param);
    }
    handleSelectChange = (value, e) => this.setState({ [value]: e.target.textContent })

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        const { onFormGoing, sendActivity, checkSent } = this.state
        return (

            <Segment>
                <Jumbotron >
                    <Container>
                        <h1>Staj Başvuru Formu</h1>
                        <p>
                            Bu formda vereceğiniz bilgiler sizinle çalışabilmemiz için ilk adımı oluşturacaktır.
                            Bizimle çalışmaya başladığınız durumda verdiğiniz bilgiler dosyanızda kalıcı olacaktır.
                            Lütfen eksiksiz bir şekilde doldurunuz
                    </p>
                    </Container>
                </Jumbotron>
                <Container>
                    {
                        onFormGoing ?
                            <Form style={{ marginTop: '1rem' }}
                                onSubmit={this.handleSubmit.bind(this)}>
                                <Col>
                                    
                                        {/* <select  class="ui dropdown"
                        placeholder='Lütfen Başvurmak İstediğiniz Pozisyonu Seçin' 
                        name="basVurPoz"
                        value={this.state.basVurPoz || ''}
                        onChange={this.handleSelectChange.bind(this, 'basVurPoz')}
                        options={BasPozisyon} >{BasPozisyon}</select> */}
                                 { /*     <select 
                                        value={this.state.basVurPoz} 
                                        onChange={(event) => this.setState({ basVurPoz: event.target.value })} 
                                        options={Staj}>
                                                                       
                                        </select>
                             */} 
                                <Form.Field required>
                                 <label className="text-muted">Staj Türü</label>
                                <Select placeholder='Staj Türünü Seçiniz'
                                name="basVurPoz"
                                value={this.state.basVurPoz || ''}
                                 onChange={this.handleSelectChange.bind(this, 'basVurPoz')}
                                options={Staj} />    
                              </Form.Field>

                                </Col>
                                <h3>1 - Kişisel Bilgiler</h3>
                                <Row>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">İsim Soyisim</label>
                                            <Input
                                                type="text"
                                                name="nameSurname"
                                                value={this.state.nameSurname || ''}
                                                onChange={this.handleChange.bind(this, 'nameSurname')}
                                                placeholder="İsim Soyisim Giriniz.."

                                            />
                                        </Form.Field>
                                    </Col>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Cep Telefonu Numarası</label>
                                            <Input
                                                type="text"
                                                name="phoneNumber"
                                                value={this.state.phoneNumber || ''}
                                                onChange={this.handleChange.bind(this, 'phoneNumber')}
                                                placeholder="Cep Telefonu Giriniz.. "
                                                maxLength="11"
                                                minLength="11"

                                            />
                                        </Form.Field>
                                    </Col>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">E-posta Adresi</label>
                                            <Input
                                                type="email"
                                                name="ePosta"
                                                value={this.state.ePosta || ''}
                                                onChange={this.handleChange.bind(this, 'ePosta')}
                                                placeholder="E-posta Adresinizi Girin"
                                            />
                                        </Form.Field>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '1rem' }}>

                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Doğum Tarihi / Gün</label>
                                            <Select placeholder='Lütfen Doğum Gününüzü Seçin'
                                                name="birthday"
                                                value={this.state.birthday || ''}
                                                onChange={this.handleSelectChange.bind(this, 'birthday')}
                                                options={Days} />
                                        </Form.Field>
                                    </Col>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Doğum Tarihi / Ay</label>
                                            <Select placeholder='Lütfen Doğum Ayınızı Seçin'
                                                name="birthmounth"
                                                value={this.state.birthmounth || ''}
                                                onChange={this.handleSelectChange.bind(this, 'birthmounth')}
                                                options={Months} />
                                        </Form.Field>

                                    </Col>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Doğum Tarihi / Yıl</label>
                                            <Select placeholder='Lütfen Doğum Yılınızı Seçin'
                                                name="birthyear"
                                                value={this.state.birthyear || ''}
                                                onChange={this.handleSelectChange.bind(this, 'birthyear')}
                                                options={Years} />
                                        </Form.Field>


                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '1rem' }}>
                                    <Col>
                                        <Form.Field >
                                            <label className="text-muted">İkametgah Adresi</label>
                                            <TextArea
                                                name="addres"
                                                className="text-primary"
                                                value={this.state.addres || ''}
                                                onChange={this.handleChange.bind(this, 'addres')}
                                                placeholder="Lütfen İkametgah Adresini Giriniz"

                                            />
                                        </Form.Field>
                                    </Col>
                                </Row>
                                <h3>2 - Eğitim Bilgileri</h3>
                                <Row style={{ marginTop: '1rem' }}>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Eğitim Durumu</label>
                                            <Select placeholder='Eğitim Durumu'
                                                name="estatusOne"
                                                value={this.state.estatusOne || ''}
                                                onChange={this.handleSelectChange.bind(this, 'estatusOne')}
                                                options={StajEgitim} />
                                        </Form.Field>
                                    </Col>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Okul Adı</label>
                                            <Input
                                                type="text"
                                                name="schoolNameOne"
                                                value={this.state.schoolNameOne || ''}
                                                onChange={this.handleChange.bind(this, 'schoolNameOne')}
                                                placeholder="Mezun Olduğunuz Okul Adını Yazınız"
                                                required

                                            />
                                        </Form.Field>
                                    </Col>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Bölümü</label>
                                            <Input
                                                type="text"
                                                name="departmentNameOne"
                                                value={this.state.departmentNameOne || ''}
                                                onChange={this.handleChange.bind(this, 'departmentNameOne')}
                                                placeholder="Mezun Olduğunuz Bölümü Yazın"
                                                required
                                            />
                                        </Form.Field>
                                    </Col>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Başlangıç Tarihi</label>
                                            <Select placeholder='Başlangıç Tarihi Seçin'
                                                name="estartDateOne"
                                                value={this.state.estartDateOne || ''}
                                                onChange={this.handleSelectChange.bind(this, 'estartDateOne')}
                                                options={Years} />
                                        </Form.Field>
                                    </Col>
                                    <Col>
                                        <Form.Field required>
                                            <label className="text-muted">Bitiş Tarihi</label>
                                            <Select placeholder='Bitiş Tarihi Seçin'
                                                name="esFinishDateOne"
                                                value={this.state.esFinishDateOne || ''}
                                                onChange={this.handleSelectChange.bind(this, 'esFinishDateOne')}
                                                options={Years} />
                                        </Form.Field>
                                    </Col>
                                </Row>
                                
                                
                                <Row style={{ marginTop: '1rem' }}>
                                    <Col>
                                        <Form.Field >
                                            <label className="text-muted">Eklemek istediğiniz herhangi bir bilgi mevcut mudur? (yabancı dil,sertifika bilgisi, kurs, eğitim, seminer vs )</label>
                                            <TextArea
                                                name="someinfo"
                                                className="text-primary"
                                                value={this.state.someinfo}
                                                onChange={this.handleChange.bind(this, 'someinfo')}
                                                placeholder="Ek olarak eklemek istediğiniz bilgi varsa yazabilirsiniz..."
                                            />
                                        </Form.Field>
                                    </Col>
                                </Row>
                                <br></br>
                                <><Checkbox style={{ marginTop: '1rem' }} onClick={this.handleCheck} label="KVKK Metnini Okudum Anladım, Kabul Ediyorum" /></>   <Button onClick={this.openModal}>KVKK METNiNİ GÖRMEK İÇİN TIKLAYIN</Button>
                                <Modal size="md" show={this.state.isOpen} onHide={this.closeModal} aria-labelledby="example-modal-sizes-title-xl">
                                    <Modal.Header closeButton>
                                        <Modal.Title>Kişisel Verilerin Korunması Kanunu</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form onSubmit={event => this.createPozitionHandler(event)}>
                                            <Container>
                                                <p>
                                                    İş talebinde bulunan kişi, 07.04.2016 tarihli 29677 sayılı Resmi Gazete’ de yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu ile diğer ilgili mevzuat kapsamında; işveren ile işe başvuru süreci kapsamında bu formla birlikte paylaşmış olduğu kişisel verilerini (isim, soy isim, cep telefon numaraları, doğum tarihi, adres bilgileri, cinsiyet vb.) ile özel nitelikli kişisel verilerini (sendika/dernek/vakıf üyeliklerine ilişkin bilgiler, ceza mahkûmiyeti, güvenlik tedbirleriyle ilgili veriler vb.) işveren  tarafından Kanun’a uygun şekilde saklanmasına, kaydedilmesine, güncellenmesine, sınıflandırılmasına ve Kanun’da sayılan şekillerde işlenebilmesine izin verdiğini kabul, beyan ve taahhüt etmiştir.
                                           </p>
                                            </Container>
                                        </Form>
                                    </Modal.Body>
                                </Modal>
                                <br></br>
                                <hr></hr>
                                {
                                    checkSent ?
                                        <div>
                                            <Button positive variant="primary" type="submit" value="send">
                                                Gönder
                        </Button>
                                        </div> :
                                        <div>
                                            <Button positive variant="primary" disabled type="submit" value="send">
                                                Gönder
                        </Button>
                                        </div>
                                }
                            </Form>

                            : <><Message
                                success
                                header='Başvurunuz Başarıyla Alındı'
                                content='Başvurunuz İnsan Kaynakları Departmanımıza iletilmiştir. Sağlıklı Günler Dileriz.'
                            /></>
                    }
                    {
                        sendActivity ?
                            <div>
                            </div>
                            :
                            <>
                                <Message
                                    error
                                    header='Bazı eksiklikler var lütfen doldurunuz'
                                    list={[this.state.errorArrayList[0], this.state.errorArrayList[1], this.state.errorArrayList[2], this.state.errorArrayList[3], this.state.errorArrayList[4], this.state.errorArrayList[5],
                                    this.state.errorArrayList[6], this.state.errorArrayList[7], this.state.errorArrayList[8], this.state.errorArrayList[9], this.state.errorArrayList[10], this.state.errorArrayList[11],
                                    this.state.errorArrayList[12], this.state.errorArrayList[13], this.state.errorArrayList[14], this.state.errorArrayList[15], this.state.errorArrayList[16], this.state.errorArrayList[17]]}
                                ></Message>
                            </>

                    }
                </Container>

            </Segment>

        )
    }
}

export { StajBasvuruFormu }
