import React from "react";
import { useState } from "react";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import {mapData} from './mapData';
import { Row } from "react-bootstrap";


const Map = () => {
  const [continentMap] = useState(worldMill);
 
  return (
    <Row style={{marginTop: '20px', height:'400px'}}>
      
      <VectorMap
        map={continentMap}
        backgroundColor="#fafafa"
        zoomOnScroll={true}
        containerStyle={{
          width: "100%",
          zoom: "150%",
          minHeight: "400px",
          maxHeight: "400px",
          boxShadow: "0 10px 50px 0 rgba(0, 0, 0, 0.25)"
        }}
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#cccccc",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0,
            "transition": '0.3s',
          },
          hover: {
            "fill-opacity": 0.6,
            cursor: "pointer"
          }
        }}
            
        series={{                  
          regions: mapData         
        }
      }
      />
      
    </Row>

    
  );
};
export default Map;