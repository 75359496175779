const roleOptions = [
    { key: 'd', text: 'Rol'},
    { key: 'a', text: 'Admin', value: 'Admin'+Math.random() },
    { key: 'm', text: 'Personel', value: 'Personel'+Math.random() },
    { key: 'c', text: 'Müşteri', value: 'Musteri'+Math.random() },
    { key: 'hrs', text: 'İnsan Kaynakları Uzmanı', value: 'HRS'+Math.random() },
    { key: 'hrj', text: 'İnsan Kaynakları Uzman Yardımcısı', value: 'HRJ'+Math.random() },
    { key: 'kiu', text: 'Kurumsal İlişkiler Uzmanı', value: 'KIU'+Math.random() }
]

export {roleOptions};