import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Segment} from 'semantic-ui-react';
import PageTitle from '../content/pageTitle';
import ImageBox from './imageBox';

class Certificate extends Component {
    render(){
        return(
            <>
                <Container style={{paddingTop:"10px"}}>
                        <PageTitle pageName={'Certificate'}/>
                </Container>
                <Segment>
                    <Container>

                    <div style={{display:"inline-block", margin:"10px"}}>  
                    <a href={'/certificates/mefarsertifikalari.pdf'}><img src="/images/Buton.Mefar.Sertifika.EN.png" class="ui medium top aligned image" /></a>
                    </div> 
                    <div style={{display:"inline-block" ,margin:"10px"}}>  
                    <a href={'/certificates/birgisertifikalari.pdf'}><img src="/images/Buton.Birgi.Sertifika.EN.png" class="ui medium top aligned image"/></a>
                    </div> 
                    <div style={{display:"inline-block", margin:"10px"}}>  
                    <a href={'/certificates/gmpsertifikalari.pdf'}><img src="/images/Buton.GMP.Sertifika.EN.png" class="ui medium top aligned image"/></a>
                    </div> 
                     
                    <h5>Information Security Policy</h5>
                    <ul>
                    <li>The BirgiMefar Group of Companies strives to create a sustainable environment for continuous improvement with its expert and motivated employees.</li>
                    <li>While doing so ;</li>
                    <li>We promise to ensure the prevention of all employees potential legal consequences which may arise from any breach of information security,</li>
                    <li>We promise to ensure the protection of highly critical data such as financial and technical,</li>
                    <li>We promise to ensure the confidentiality and integrity of the information systems,</li>
                    <li>We promise to ensure the continuity of the business,</li>
                    <li>We promise to prtotect the reputation and investments of our society, clients and suppliers,</li>
                    <li>For these objectives;</li>
                    <li>Up-to-date security technologies and regulations are followed,</li>
                    <li>Continuous improvement and development measures are taken,</li>
                    <li>Ongoing training to our employess is provided,</li>
                    <li>New targets and goals are set every year for each process and periodic evalutaions are done to evaluate the progress,</li>
                    </ul>
                    <h5>Policy of Customer Satisfaction and Handling Customer Complaints</h5>
                    <p>In accordance with our corporate culture as Birgi Mefar group of companies satisfaction of our customers and using our products by end-users in a complete confidence are our priorities.To this end;</p>
                    <ul>
                    <li>1. Customers' and end-users' complaints are handled in a quick, attentive, in compliance with regulations and laws and TS ISO 10002 standard,</li>
                    <li>2. Every step of investigating complaints is performed in compliance with the principles of transparency,confidentiality and being fair. nobody, institutions and parties are given deceptive and misleading information.</li>
                    <li>3. All complaints and claims are treated as added value opportunities and continuous improvement.Therefore complaints are evaluated in an efficient and effective manner and concluded certainly</li>
                    <li>4. Corrective and preventive actions required in order to prevent repetition are determined and applied without any delay</li>
                    <li>5. Our complaint management system is periodically reviewed and efficiency of the system is reported to the company management.</li>
                    <li>6. Our main goal is to eliminate all kind of situations that may create any complaints.All kinds of precautions needed for this are taken and monitored.</li>
                    </ul>
                    
                    <p>Executive Committee</p>
                    <p>Release Date: 08.03.2016</p>

                    <h5>BirgiMefar Personel Data Protection and Processing Policy</h5>
                    <a href={'/kvkk/Mefar_KVKK_Politikası.pdf'}>Click for Personel Data Protection and Processing Policy</a>
                    
                </Container>
            </Segment>
        </>
        )
    }
}
export {Certificate}