import {NavBar as Header} from '../components/navTR';
import {Footer} from '../components/footerTR';
import {CookieTR} from '../components/cookieTR';
import React from 'react';
import { ContactGovernanceFormTR } from '../components';



const ContactGovernanceTR= () =>  {
    return(
        <>
            <Header />
            <ContactGovernanceFormTR/>
            <Footer />
            <CookieTR/>
        </>
    )
}

export default ContactGovernanceTR;