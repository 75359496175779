import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Segment} from 'semantic-ui-react';
import PageTitle from '../content/pageTitle';
import ImageBox from './imageBox';

class CertificateTR extends Component {
    render(){
        return(
            <>
                <Container style={{paddingTop:"10px"}}>
                        <PageTitle pageName={'KALİTE POLİTİKASI'}/>
                </Container>
                <Segment>
                    <Container>

                    <div style={{display:"inline-block", margin:"10px"}}>  
                    <a href={'/certificates/mefarsertifikalari.pdf'}><img src="/images/Buton.Mefar.Sertifika.png" class="ui medium top aligned image" /></a>
                    </div> 
                    <div style={{display:"inline-block" ,margin:"10px"}}>  
                    <a href={'/certificates/birgisertifikalari.pdf'}><img src="/images/Buton.Birgi.Sertifika.png" class="ui medium top aligned image"/></a>
                    </div> 
                    <div style={{display:"inline-block", margin:"10px"}}>  
                    <a href={'/certificates/gmpsertifikalari.pdf'}><img src="/images/Buton.GMP.Sertifika.png" class="ui medium top aligned image"/></a>
                    </div> 
                    
                    <h5>BİLGİ GÜVENLİĞİ POLİTİKASI</h5>
                    <ul>
                    <li>BirgiMefar şirketler gurubu sürdürülebilir ve gelişmeye açık bir gelecek için her ortam ve koşulda konularında uzman personeliyle yürüttüğü tüm faaliyetlerini yaparken;</li>
                    <li>Bilgi güvenliği ihlalinden doğacak muhtemel kanuni yükümlülüklerden şirket üst yönetimi ve diğer tüm çalışanların etkilenmesini önlemeyi,</li>
                    <li>Bilgi sistemlerinde paylaşılmakta olan idari, mali ve teknik veriler ile kritik tüm verilerin güvenliğini sağlamayı,</li>
                    <li>İş devamlılığını sağlamayı,</li>
                    <li>Kurumumuz ve yatırımlarımız ile birlikte toplumun, tedarikçilerimizin ve müşterilerimizin itibarını korumayı taahhüt eder.</li>
                    <li>Bu amaçla; Güncel güvenlik teknolojileri ve kuralları takip edilir,</li>
                    <li>Sürekli iyileştirme ve geliştirme için tedbirler alınır,</li>
                    <li>Personelin sürekli eğitim ve sorumluluk alması sağlanır,</li>
                    <li>Bu taahhüdü yerine getirebilmek için tüm proseslerde her yıl yeni hedefler belirlenir ve bu hedefler periyodik olarak gözden geçirilir,</li>
                    </ul>
                    <p>Bilgi güvenliğinin sağlanması için azami özen gösterilir.</p>
                    <p>Çıkış tarihi:08.03.2016</p>

                    <h5>MÜŞTERİ MEMNUNİYETİ VE MÜŞTERİ ŞİKAYETLERİNİ ELE ALMA POLİTİKASI</h5>
                    <p>BirgiMefar şirketler grubu olarak firma kültürümüz gereği müşterilerimizin memnuniyeti ve son kullanıcının ürünlerimizi tam bir güven içerisinde kullanması asli önceliğimizdir.Bunun için;</p>
                    <ul>
                    <li>Müşterilerimizin ve son kullanıcıların şikayetleri, hızlı, özenli, yasalara ve mevzuatlara uygun şekilde, TS ISO 10002 standardına uygun olarak ele alnır.</li>
                    <li>Şikayet araştırmalarında her adım; şeffaflık, objektiflik, gizlilik ve adil olma ilkelerine uygun şekilde yürütülür.Hiçbir kişi, kurum ve zümreye aldatıcı, yanıltıcı bilgi verilmez.</li>
                    <li>Tüm şikayet ve talepler, firmamız için bir katma değer fırsatı ve sürekli iyileştirmenin bir parçası olarak görülür.Bu nedenle şikayetler etkili ve verimli bir şekilde değerlendirilerek mutlak bir sonuca ulaştırılır.</li>
                    <li>Şikayet konularının tekrar etmesinin önlenmesi için gereken düzeltici ve önleyici faaliyetler belirlenir ve gecikme olmaksızın uygulanır.</li>
                    <li>Şikayet yönetim sistemimiz periyodik aralıklarla düzenli olarak gözden geçirilir ve sistemin etkinliği firma yönetimine raporlanır.</li>
                    <li>Temel hedefimiz şikayet oluşmasına neden olabilecek her türlü durumun önlenmesidir.Bunun için gereken her türlü önlem alınır ve izlenir.</li>
                    </ul>
                

                    <h5>BirgiMefar Ki̇şi̇sel Veri̇leri̇n Korunmasi Ve İşlenmesi̇ Poli̇ti̇kasi</h5>
                    <a href={'/kvkk/Mefar_KVKK_Politikası.pdf'}>Kişisel Verilerin Korunması ve İşlenmesi Politikası İçin Tıklayın</a>
                    
                </Container>
            </Segment>
        </>
        )
    }
}
export {CertificateTR}