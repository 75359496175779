import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Segment} from 'semantic-ui-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen,faBullhorn } from '@fortawesome/free-solid-svg-icons';
import {onClickDownload} from '../helpers'
import IframeBox from '../NewContactGovernance/IframeBox';

class ContactGovernanceForm extends Component {
    render(){
        return(
            <>
     <Segment>
    <Container>
       
                    <h5 >ETHICAL VIOLATION NOTIFICATION</h5>
                    <ul>
                    <li><b>Digital Ethics Violation Reporting Platform has many language options, including Turkish and English. You can choose the language you want from the language sections at the top right.</b></li>
                    <li>Birgi Mefar Group aims to provide a safe, ethical and harmonious working environment to all its internal and external stakeholders and to ensure that this FAIR AND TRANSPARENT governance environment is adopted by all stakeholders.</li>
                    <li>All BMG stakeholders, regardless of their position, have the same right and responsibility to help ensure compliance and promote a positive work culture.</li>
                    <li>Digital Ethical Violation Reporting Platform enables you to report ethical concerns you encounter in the workplace 24/7, ANONYMOUS AND CONFIDENTIALLY, through Star Compliance, a third-party collaboration.</li>                  
                    </ul>
            <Row  style={{ margin:"1.5rem"}}>
            <Col onClick={event => onClickDownload(event, "https://birgimefar.ipm.ae.starcompliance.com/#landing")} className="text-center hoverCard" xl={12} xs={6} style={{borderRadius:"20px 20px 20px",backgroundColor: 'rgb(11, 75, 147)',marginLeft:"auto",marginRight:"auto", padding:'2vh'}} >
            <h5 style={{ color: 'white', paddingTop: '3px'}}>
                    <FontAwesomeIcon icon={faBullhorn} color='white' style={{fontSize: '48px'}} />
                </h5>
                <h3 style={{fontWeight:'bold', color: 'white', paddingTop: '5px'}}>
                  Introduction to the Digital Speak Up Platform
                </h3>
               
            </Col>
              </Row>
           <Row>
            
           <Col>
                    <a href={'mailto:etikhat@mefar.com'}>You Can Send an Email for Ethical Violation Notification</a>

                    </Col>
                <Col style={{textAlign:'right'}}>
                <IframeBox src={'/videos/Mefar.Etik.Hat.mp4'}  />
                </Col>
                </Row>
                </Container>
            </Segment>
        </>
        )
    }
}
export {ContactGovernanceForm}