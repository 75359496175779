import { NavBar as Header, Footer, Content, Cookie, AquilaForm } from '../components';
import React from 'react';


const AquilaProjectSuggest = () => {

    return (
        <>
            <AquilaForm />
        </>
    )
}

export default AquilaProjectSuggest;