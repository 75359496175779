import React, { Component } from 'react';
import agent from '../../../axios/agent';
import { Segment, Select, Checkbox, Dropdown, } from 'semantic-ui-react';
import { Depatman } from '../constantArrays';
import { Form, Button, Container, Col, Modal, Row } from 'react-bootstrap';
import SuccessLogo from './tick.png';
import FalseLogo from './false.png';
import emailjs from 'emailjs-com';


class ISGClaimTR extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nameSurname: "",
            phoneNumber: "",
            companyName: "",
            email: "",
            subject: "",
            description: "",
            work: "",
            date: new Date(),
            onFormGoing: true,
            formCanceled: false,
            isOpen: false,
            isOpen1: false,
            isSelectedDepartment: false,
            status: null,
        }
        this.createComplaintsHandler = this.createComplaintsHandler.bind(this)
    }

    createComplaintsHandler(event) {

        event.preventDefault();
        const { nameSurname, phoneNumber, companyName, email, subject, description, work, date, status } = this.state;

        let templateParams = {
            user_subject: companyName,
            message: '<hr></hr>Firma Yetkilisi Ad Soyad : ' + nameSurname + '<hr></hr>' + 'Cep Telefonu: ' + phoneNumber + '<hr></hr>' + 'Email: ' + email+ '<hr></hr>' + 'Firma İsmi: ' + companyName + '<hr></hr>' + 'Görüşülecek Departman: ' + subject+ '<hr></hr>' + 'Görüşülecek Yetkili: ' + description+ '<hr></hr>' + 'Yapılacak İş: ' + work,
        }
            emailjs.send(
                'service_6yte8pi',
                'template_aj44hfs',
                templateParams,
                'BkydqdaqRbes5OgJX'
            )  .then(response => {
                if (response.status === 200)
                    this.setState({
                        onFormGoing: false
                    })
                else this.setState({
                    formCanceled: true
                })
            })
    
    }
    handleSelectChange = (value, e) => this.setState({ [value]: e.target.textContent })

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    render() {
        const { formCanceled, onFormGoing } = this.state
        return (
            <Segment style={{ marginTop: '25px' }}>
                <h2 style={{
                    textAlign: 'center',
                    padding: '10px'
                }}>Dış Hizmet Alınan Firmalar İçin <br></br>Bilgi Sistemi Kayıt Formu</h2>
                
                    {onFormGoing ?
                        <Container style={{ fontSize: '15px' }} >
                            <Segment>
                                <Form onSubmit={event => this.createComplaintsHandler(event)}>
                                    <Form.Group>
                                        <Form.Row style={{ marginTop: '25px' }} >
                                            <Col>
                                                <Form.Label >Firma Yetkili Ad Soyad</Form.Label>
                                                <Form.Control controlid="formBasicSubject"
                                                    placeholder="Firma Yetkilisi Ad Soyad"
                                                    value={this.state.nameSurname}
                                                    onChange={(event) => { this.setState({ nameSurname: event.target.value }) }}
                                                    required />
                                                <Form.Text className="text-muted"></Form.Text>
                                            </Col>
                                            <Col>
                                                <Form.Label>Cep Telefon Numarası</Form.Label>
                                                <Form.Control ccontrolid="formBasicSubject"
                                                    placeholder="Cep Telefon Numarası 0 ile başlamalıdır"
                                                    value={this.state.phoneNumber}
                                                    onChange={(event) => { this.setState({ phoneNumber: event.target.value }) }}
                                                    maxLength={11}
                                                    minLength={11}
                                                    required />
                                                <Form.Text className="text-muted"></Form.Text>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row style={{ marginTop: '10px' }}>
                                            <Col>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control controlid="formBasicSubject"
                                                    placeholder="Email"
                                                    value={this.state.email}
                                                    onChange={(event) => { this.setState({ email: event.target.value }) }}
                                                    required />
                                            </Col>
                                            <Col>
                                                <Form.Label>Firma</Form.Label>
                                                <Form.Control controlid="formBasicSubject"
                                                    placeholder="SSK da kayıtlı firma ismi tam girilmelidir"
                                                    value={this.state.companyName}
                                                    onChange={(event) => { this.setState({ companyName: event.target.value }) }}
                                                    required />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row style={{ marginTop: '10px' }}>
                                        <Col>
                                    
                                           <Form.Label>Görüşülecek Departman</Form.Label>
                                           <Dropdown
                                               placeholder='Görüşülecek Departman'
                                               value={this.state.subject}
                                               fluid
                                               selection
                                               onChange={this.handleSelectChange.bind(this, 'subject')}
                                               options={Depatman}
                                             />
                                     </Col>
                                     </Form.Row>
                                        <Form.Row style={{ marginTop: '10px' }}>
                                            <Col>
                                                <Form.Label>Görüşülecek Yetkili</Form.Label>
                                                <Form.Control controlid="formBasicSubject"
                                                    placeholder="Görüşülecek Yetkili"
                                                    value={this.state.description}
                                                    onChange={(event) => { this.setState({ description: event.target.value }) }}
                                                    required />
                                            </Col>
                                        </Form.Row>
                                        <Form.Label style={{ marginTop: '10px' }}>Yapılacak İş</Form.Label>
                                        <Form.Control as="textarea" rows={3}
                                            placeholder="Lütfen yapacağınız işi bir kaç cümleyle açıklayınız"
                                            value={this.state.work}
                                            onChange={(event) => { this.setState({ work: event.target.value }) }}
                                            required />

                                    </Form.Group>
                                   
                                    <Button variant="primary" type="submit" value="send">
                                        Gönder
                                    </Button>
                                    
                                    <div style={{ float: 'right', marginRight: '10px' }}
                                        className=""
                                    >
                                        <Button variant="secondary" onClick={this.openModal}>
                                            Veri Sahibi
                                        </Button>
                                    </div>

                                    <Modal size="xl" show={this.state.isOpen} onHide={this.closeModal} aria-labelledby="example-modal-sizes-title-xl" >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Veri Sahibi</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <h5>BIRGI MEFAR İŞLETME GRUBU, VERİ SAHİBİ BAŞVURU FORMU</h5>
                                            <p>
                                                <p>
                                                    Kişisel verilerin işlenmesine ilişkin özel haklar, Kanun'un 11. Maddesinde, 567 sayılı Kişisel Verilerin Korunması Kanunu'nda ("Kanun") ilgili kişi olarak tanımlanan kişisel veri sahiplerine ("Veri Sahibi") tanınmıştır. 6698.
                                                </p>
                                                <p>
                                                    Kanunun 13 üncü maddesinin birinci fıkrası gereğince veri sorumlusu olan Şirketimize bu haklarla ilgili yapılacak başvurunun yazılı olması veya Kişisel Verilerin Korunması tarafından tanımlanan diğer yöntemlerle tarafımıza iletilmesi gerekmektedir. Board ("Board)". Bu çerçevede Şirketimize "yazılı" olarak yapılacak başvurular bu form kullanılarak fiziki ortamdan noter veya taahhütlü e-posta yoluyla tarafımıza iletilmelidir.
                                                </p>
                                                <p>
                                                    Tarafımıza iletilen başvurularınıza 13. maddenin 2. fıkrasına göre talebinizin niteliğine göre talebinizin bize ulaştığı tarihten itibaren "en geç otuz gün ve en kısa sürede" yanıtlanacaktır. cevaplar size Kanun'un 13. maddesi hükmü uyarınca elektronik ortamdan veya yazılı olarak iletilecektir.
                                                </p>
                                                <p>
                                                    Bu formun mahiyeti ve talebiniz gereği sizden talep edilen bilgi ve belgelerin doğru ve doğru bir şekilde tarafımıza ulaştırılması gerekmektedir. İstenilen bilgi ve belgelerin nasıl olması gerektiğine dair temin edilmediği durumlarda Şirketimizin talebi doğrultusunda yapılacak çalışmaların eksiksiz ve nitelikli bir şekilde yürütülmesinde arızalar yaşanabilir. Bu durumda şirketimizin yasal hakları saklıdır. Bu nedenle ilgili form, talebinize göre istenen bilgi ve belgeleri doğru b ir şekilde içerecek şekilde gönderilmelidir.
                                                </p>
                                            </p>
                                            <p>
                                                VERİ SAHİBİ BİLGİLERİ
                                            </p>
                                            <p>
                                                Sizinle iletişime geçebilmemiz ve kimliğinizi doğrulayabilmemiz için aşağıdaki alanları doldurunuz.
                                            </p>
                                            <p>
                                                Ad Soyad
                                            </p>
                                            <p>
                                                Türkiye Cumhuriyeti TC Kimlik Numarası:
                                            </p>
                                            <p>
                                                Adres
                                            </p>
                                            <p>
                                                Cep telefonu
                                            </p>
                                            <p>
                                                E-posta
                                            </p>
                                            <p>
                                                Şirketimizle ilişkiniz
                                            </p>
                                            <p>
                                                Müşteri
                                            </p>
                                            <p>
                                                Ziyaretçi
                                            </p>
                                            <p>
                                                İş ortağı
                                            </p>
                                            Diğer
                                            <p>
                                                Bunun yanı sıra yukarıda belirttiğiniz bilgileriniz başvurunuz ile ilgili daha detaylı bilgi almak, soruşturma süreciyle ilgili bilgilendirmek ve başvuru sonuçlarını iletmek için talep edilebilir.
                                            </p>
                                            <p>
                                                UYGULAMA İÇERİĞİ
                                            </p>
                                            <p>
                                                Lütfen talebinize konu olan kişisel verilerinizin ne olduğunu belirtin. Bu bağlamda kişisel verilere ilişkin talebinizi aşağıda belirtmenizi rica ederiz.
                                            </p>
                                            <p>
                                                BAŞVURU SAHİBİ BEYANI
                                            </p>
                                            <p>
                                                Yukarıda belirttiğim talepler doğrultusunda Şirketinize yapmış olduğum başvurum Kanunun 13. maddesi kapsamında değerlendirildikten sonra bilgilendirilmemi rica ederim.
                                            </p>
                                            <p>
                                                Bu başvuruda sizlere vermiş olduğum bilgi ve belgelerimin doğru ve güncel olduğunu, ek olarak Kurul tarafından belirlenen ücreti ödemem gerektiği konusunda aydınlatılmış olduğumu taahhüt ve beyan ederim. Şirketin başvurumu sonuçlandırması için ücret talep edilebilir ve ek bilgi talep edilebilir.
                                            </p>
                                            <p>
                                                Cevabın Bölüm 2'de verdiğim posta adresime gönderilmesini istiyorum.
                                            </p>
                                            <p>
                                                Cevapların Bölüm 2'de verdiğim e-posta adresime gönderilmesini istiyorum.
                                            </p>
                                            <p>
                                                İlgili Başvuru Sahibi Kişi (Kişisel Veri Sahibi)
                                            </p>
                                            <p>
                                                Ad Soyad:
                                            </p>
                                            <p>
                                                Uygulama tarihi:
                                            </p>
                                            <p>
                                                İmza:
                                            </p>
                                        </Modal.Body>
                                    </Modal>
                                </Form>
                            </Segment>
                            <Segment >
                             <p>

Değerli Paydaşımız,<br></br><br></br>
Grup firmalarımızın dış hizmet alımlarında, Çevre, İş Sağlığı ve Güvenliği ile ilgili belirlediğimiz kurallarımız Sağlık Emniyet Çevre Taahhütnamemizde açıkça belirtilmiştir. İşe başlamadan önce Sağlık Emniyet Çevre taahhütnamesinin onaylanarak sistem üzerinden gönderilmesi gerekmektedir.
<br></br><br></br>Lütfen, aşağıdaki bağlantıyı kullanarak taahhütnameyi indiriniz:<br></br> 
<a target="_blank" href='/certificates/Birgi Mefar Sağlık Emniyet Çevre Kuralları ve Taahhütnamesi.pdf'> Sağlık Emniyet Çevre Taahhütnamesi</a>
<br></br><br></br>Söz konusu taahhütname, Grup olarak taahhüt ettiğimiz Çevre, İş Sağlığı ve Güvenliği standartlarının gerekliliklerini karşılamak amacıyla hazırlanmıştır.<br></br><br></br> Bu süreçte göstereceğiniz duyarlılık ve uyum için şimdiden teşekkür ederiz.
Herhangi bir sorunuz veya ek bilgi talebiniz olması durumunda, lütfen bizimle iletişime geçmekten çekinmeyiniz.
<br></br><br></br>Saygılarımızla,

</p>
                        </Segment>
                        </Container>
                        : <div style={{ textAlign: 'center', marginTop: '25px' }}>
                            {
                                formCanceled ?
                                    <>
                                        <img src={FalseLogo} style={{ maxHeight: '200px' }} alt='' />
                                        <h1 style={{ color: 'red' }}>Bir hata oluştu.</h1>
                                    </>
                                    :
                                    <>
                                        <img src={SuccessLogo} style={{ maxHeight: '200px' }} alt='' />
                                        <h2 style={{ color: '#32c671' }}>Bilgileriniz Sağlık Emniyet Çevre Departmanına İletilmiştir</h2>
                                        <h4 style={{ color: '#000000' }}>Sistem giriş bilgileriniz en yakın zamanda sms ve e-mail yolu ile size iletilecektir. Bilgilerin ulaşmaması halinde lütfen <a href="taserontalep@birgimefar.com">taserontalep@birgimefar.com</a> mail adresi ile iletişime geçiniz</h4>
                                    </>
                            }
                        </div>


                    }
                     
            </Segment>
        )
    }
}

export { ISGClaimTR }