import React from 'react';
import {DashBoardList} from './DbList';
import { Container } from 'semantic-ui-react'

const DashBoardMain = () => {
        return(
            <div>
            <Container  style={{marginTop: '5em'}}>
                
            </Container>
            <DashBoardList />
        </div>
        )}

export {DashBoardMain};
