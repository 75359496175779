import React from "react";
import InformationComponent from './informationComponent';

const CustomerComponents = () => {
    return(
        <>
            <InformationComponent poster={'./images/logo.png'}  video={'./videos/customer-report.mp4'} style={{marginLeft:'110px'}}/>
        </>
    )
}

export default CustomerComponents