import {BrochureForFairClass} from '../components';
import React from 'react';

const BrochuresForFairFunc = () =>  {
    return(
        <>
            <BrochureForFairClass/>
        </>
    )
}

export default BrochuresForFairFunc;