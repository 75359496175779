import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const IframeBox = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div> 
      <a variant="primary" onClick={handleShow} src={props.imgsrc} style={{cursor:'pointer', color:'green', width: props.width, height:'100%'}}  alt='Quality Policy' >Etik İhlal Bildirimi Kılavuzu</a>

      <i class="huge blue play icon" onClick={handleShow} src={props.imgsrc} style={{position:'absolute',marginLeft:'-55%', marginTop:'25%',width:'20%',height:'200%'}}></i>
      
      </div> 
      <Modal size='xl' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body> <iframe  title="YouTube video player" frameborder="0" onClick={handleShow} src={props.src} style={{ width: "100%", height:"600px"}} alt=''></iframe></Modal.Body>
      </Modal>
    </>
  )
}

export default IframeBox