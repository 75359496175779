import React, { Component } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import ShortCard from './shortCard';
import agent from '../../../axios/agent';
import { DateToDaily } from '../../../dashboard/components/helpers';
import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
//import BigCard from './bigCard';
import { scrollTo } from '../helpers';

class NewsBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      pageCount: null,
      activePage: 0
    }
    this.getNews = this.getNews.bind(this);
  }

  componentDidMount() {
    this.getNews(this.state.activePage);
  }

  getNews(pageNum) {
    scrollTo(document.body)
    agent.Activities.LastNews(pageNum).then((response) => {
      if (response.status === 200) {
        this.setState({ items: response.data.slice(0, 12).map(i => { return i }) })
      }
    })
  }

  render() {
    const { items } = this.state;

    const listShortNews = items ? items.map(item => {
      return (
        <Col xl={6} xs={12}>
          <ShortCard
            link={'/news/' + decodeURIComponent(item.link)}
            image={item.image}
            title={item.title}
            id={item.id}
            date={DateToDaily(item.date)}
            description={item.description}
            buttonName={"Detaylar"}
          />
        </Col>
      )
    }) : null

    return (
      <>
        <h2 className={'m-3 text-center'} style={{ color: 'rgb(0, 93, 171)' }}>LATEST NEWS</h2>
        {items ?
          <Container fluid style={{ width: '85%' }}>
            <Row>{listShortNews}</Row>
            <div class="d-flex justify-content-center">

              <Menu.Item as={Link} to="news" >

                <h2 className={'m-3 text-center'} style={{ color: 'rgb(0, 93, 171)' }}>   <Icon name='archive' />   Click for archive</h2>

              </Menu.Item>
            </div>
          </Container> : <div className={'text-center p-5 '}><Spinner animation="border" /></div>
        }
      </>
    )
  }
}

export default NewsBlock;