import {NavBar as Header, Footer, Content, Cookie, ComplaintsForm, ContactGovernanceForm} from '../components';
import Location from '../components/location';
import React from 'react';
import NewContactForm from '../components/NewContactForm';

const ContactGovernance = () =>  {
    return(
        <>
            <Header />
            <ContactGovernanceForm />
            <Footer />
            <Cookie/>
        </>
    )
}

export default ContactGovernance;