import {NavBar as Header} from '../components/navTR';
import {CookieTR} from '../components/cookieTR';
import {Footer} from '../components';
import { StajBasvuruFormu } from '../components/StajBasvuruFormu';
import React, { Component } from 'react';

class StajBasvuruForm extends Component  {
    render(){
        return(
            <>
                <Header/>
                <StajBasvuruFormu/>
                <CookieTR/>
                <Footer />
            </>
        )
    }
}

export default StajBasvuruForm;