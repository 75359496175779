import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Segment} from 'semantic-ui-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen,faBullhorn } from '@fortawesome/free-solid-svg-icons';
import {onClickDownload} from '../helpers'
import IframeBox from '../NewContactGovernanceTR/IframeBox';


class ContactGovernanceFormTR extends Component {
    render(){
        return(
            <>
 
                <Segment>
                    <Container>
 
 
     
                    <h5>ETİK İHLAL BİLDİRİMİ</h5>
                    <ul>
                    <li><b>Dijital Etik İhlal Bildirim Platformu, Türkçe İngilizce olmak üzere birçok dil seçeneklerine sahiptir. Sağ üst bölümde yer alan dil bölümlerinden istediğiniz dili seçebilirsiniz.</b></li>
                    <li>Birgi Mefar Grup tüm iç ve dış paydaşlarına güvenli, etik ve uyumlu bir çalışma ortamı sağlamayı ve bu ADiL VE ŞEFFAF yönetişim ikliminin tüm paydaşlar tarafından benimsenmesini hedeflemektedir.</li>
                    <li>Tüm BMG paydaşları, pozisyonlarına bakılmaksızın, uyumluluğun sağlanmasına yardımcı olmak, olumlu bir çalışma kültürünü teşvik etmek için aynı hak ve sorumluluğa sahiptir.</li>
                    <li>Dijital Etik İhlal Bildirim Platformu, iş yerinde karşılaştığınız etik kaygıların üçüncü taraf işbirliği olan Star Compliance aracılığı ile 7/24, ANONİM VE GİZLİ olarak bildirilmesini sağlar.</li>
                    
                    </ul>
        <Row style={{ margin:"1.5rem"}}>
            <Col onClick={event => onClickDownload(event, "https://birgimefar.ipm.ae.starcompliance.com/#landing")} className="text-center hoverCard" xl={12} xs={6} style={{borderRadius:"20px 20px 20px",backgroundColor: 'rgb(11, 75, 147)',marginLeft:"auto",marginRight:"auto", padding:'2vh'}} >
            <h5 style={{ color: 'white', paddingTop: '3px'}}>
                <FontAwesomeIcon icon={faBullhorn} color='white' style={{fontSize: '48px'}} />
            </h5>
            <h3 style={{fontWeight:'bold', color: 'white', paddingTop: '5px'}}>
                   Dijital Etik İhlal Bildirim Platformuna Giriş
            </h3> 
            </Col>

           
        </Row>
        <Row>
        <Col>
        <a href={'mailto:etikhat@mefar.com'}>Etik İhlal Bildirimi İçin E-posta Gönderebilirsiniz</a>  
        </Col>    
        <Col style={{textAlign:'right'}}>
                <IframeBox src={'/videos/Mefar.Etik.Hat.mp4'}  />
                </Col>
                </Row>
                </Container>
            </Segment>
        </>
        )
    }
}
export {ContactGovernanceFormTR}