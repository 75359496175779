import React from "react";
import HrinfComponent from './informationComponent';


const HumanSourceComponents = () => {
    return(
      
               <HrinfComponent></HrinfComponent>
    
    )
}

export default HumanSourceComponents