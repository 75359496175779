const StajEgitim = [
  { key: 'Lisans 1. Sınıf',  value: 'Lisans 1. Sınıf' ,  text: 'Lisans 1. Sınıf' },
  { key: 'Lisans 2. Sınıf',  value: 'Lisans 2. Sınıf' ,  text: 'Lisans 2. Sınıf' },
  { key: 'Lisans 3. Sınıf',  value: 'Lisans 3. Sınıf' ,  text: 'Lisans 3. Sınıf' },
  { key: 'Lisans 4. Sınıf',  value: 'Lisans 4. Sınıf' ,  text: 'Lisans 4. Sınıf' },
  { key: 'Lisans - Mezun',  value: 'Lisans - Mezun' ,  text: 'Lisans - Mezun' },
]
const  Staj = [
  { key: '12', value: 'Zorunlu Staj', text: 'Zorunlu Staj' },
  { key: '13', value: 'Gönüllü Staj', text: 'Gönüllü Staj' },
]
  const Cinsiyet = [
    { key: 'Kadın', value: 'Kadın', text: 'Kadın' },
    { key: 'Erkek', value: 'Erkek', text: 'Erkek' },
  ]
  const MedeniDurum = [
    { key: 'Bekar', value: 'Bekar', text: 'Bekar' },
    { key: 'Evli', value: 'Evli', text: 'Evli' },
  ]
  const AskelikDurumu = [
    { key: 'Tamamlandı', value: 'Tamamlandı', text: 'Tamamlandı' },
    { key: 'Tecilli', value: 'Tecilli', text: 'Tecilli' },
    { key: 'Muaf', value: 'Muaf', text: 'Muaf' },
  ]
  const Durum = [
    { key: 'Evet', value: 'Evet', text: 'Evet' },
    { key: 'Hayır', value: 'Hayır', text: 'Hayır' },
  ]
  const BasPozisyon = [
    { key: 'GB', value: 'Genel Başvuru', text: 'Genel Başvuru' },
    { key: 'YGU', value: 'Yazılım Uzmanı', text: 'Yazılım Uzmanı' },
  ]
  const Days = [
    { key: '1', value: '1', text: '1' },
    { key: '2', value: '2', text: '2' },
    { key: '3', value: '3', text: '3' },
    { key: '4', value: '4', text: '4' },
    { key: '5', value: '5', text: '5' },
    { key: '6', value: '6', text: '6' },
    { key: '7', value: '7', text: '7' },
    { key: '8', value: '8', text: '8' },
    { key: '9', value: '9', text: '9' },
    { key: '10', value: '10', text: '10' },
    { key: '11', value: '11', text: '11' },
    { key: '12', value: '12', text: '12' },
    { key: '13', value: '13', text: '13' },
    { key: '14', value: '14', text: '14' },
    { key: '15', value: '15', text: '15' },
    { key: '16', value: '16', text: '16' },
    { key: '17', value: '17', text: '17' },
    { key: '18', value: '18', text: '18' },
    { key: '19', value: '19', text: '19' },
    { key: '20', value: '20', text: '20' },
    { key: '21', value: '21', text: '21' },
    { key: '22', value: '22', text: '22' },
    { key: '23', value: '23', text: '23' },
    { key: '24', value: '24', text: '24' },
    { key: '25', value: '25', text: '25' },
    { key: '26', value: '26', text: '26' },
    { key: '27', value: '27', text: '27' },
    { key: '28', value: '28', text: '28' },
    { key: '29', value: '29', text: '29' },
    { key: '30', value: '30', text: '30' },
    { key: '31', value: '31', text: '31' },
  ]
  const Months = [
    { key: 'Ocak', value: '01', text: '01' },
    { key: 'Şubat', value: '02', text: '02' },
    { key: 'Mart', value: '03', text: '03' },
    { key: 'Nisan', value: '04', text: '04' },
    { key: 'Mayıs', value: '05', text: '05' },
    { key: 'Haziran', value: '06', text: '06' },
    { key: 'Temmuz', value: '07', text: '07' },
    { key: 'Ağustos', value: '08', text: '08' },
    { key: 'Eylül', value: '09', text: '09' },
    { key: 'Ekim', value: '10', text: '10' },
    { key: 'Kasım', value: '11', text: '11' },
    { key: 'Aralık', value: '12', text: '12' },
  ]
  const Years = [
    { key: '2030', value: '2030', text: '2030' },
    { key: '2029', value: '2029', text: '2029' },
    { key: '2028', value: '2028', text: '2028' },
    { key: '2027', value: '2027', text: '2027' },
    { key: '2026', value: '2026', text: '2026' },
    { key: '2025', value: '2025', text: '2025' },
    { key: '2024', value: '2024', text: '2024' },
    { key: '2023', value: '2023', text: '2023' },
    { key: '2022', value: '2022', text: '2022' },
    { key: '2021', value: '2021', text: '2021' },
    { key: '2020', value: '2020', text: '2020' },
    { key: '2019', value: '2019', text: '2019' },
    { key: '2018', value: '2018', text: '2018' },
    { key: '2017', value: '2017', text: '2017' },
    { key: '2016', value: '2016', text: '2016' },
    { key: '2015', value: '2015', text: '2015' },
    { key: '2014', value: '2014', text: '2014' },
    { key: '2013', value: '2013', text: '2013' },
    { key: '2012', value: '2012', text: '2012' },
    { key: '2011', value: '2011', text: '2011' },
    { key: '2010', value: '2010', text: '2010' },
    { key: '2009', value: '2009', text: '2009' },
    { key: '2008', value: '2008', text: '2008' },
    { key: '2007', value: '2007', text: '2007' },
    { key: '2006', value: '2006', text: '2006' },
    { key: '2005', value: '2005', text: '2005' },
    { key: '2004', value: '2004', text: '2004' },
    { key: '2003', value: '2003', text: '2003' },
    { key: '2002', value: '2002', text: '2002' },
    { key: '2001', value: '2001', text: '2001' },
    { key: '2000', value: '2000', text: '2000' },
    { key: '1999', value: '1999', text: '1999' },
    { key: '1998', value: '1998', text: '1998' },
    { key: '1997', value: '1997', text: '1997' },
    { key: '1996', value: '1996', text: '1996' },
    { key: '1995', value: '1995', text: '1995' },
    { key: '1994', value: '1994', text: '1994' },
    { key: '1993', value: '1993', text: '1993' },
    { key: '1992', value: '1992', text: '1992' },
    { key: '1991', value: '1991', text: '1991' },
    { key: '1990', value: '1990', text: '1990' },
    { key: '1989', value: '1989', text: '1989' },
    { key: '1988', value: '1988', text: '1988' },
    { key: '1987', value: '1987', text: '1987' },
    { key: '1986', value: '1986', text: '1986' },
    { key: '1985', value: '1985', text: '1985' },
    { key: '1984', value: '1984', text: '1984' },
    { key: '1983', value: '1983', text: '1983' },
    { key: '1982', value: '1982', text: '1982' },
    { key: '1981', value: '1981', text: '1981' },
    { key: '1980', value: '1980', text: '1980' },
    { key: '1979', value: '1979', text: '1979' },
    { key: '1978', value: '1978', text: '1978' },
    { key: '1977', value: '1977', text: '1977' },
    { key: '1976', value: '1976', text: '1976' },
    { key: '1975', value: '1975', text: '1975' },
    { key: '1974', value: '1974', text: '1974' },
    { key: '1973', value: '1973', text: '1973' },
    { key: '1972', value: '1972', text: '1972' },
    { key: '1971', value: '1971', text: '1971' },
    { key: '1970', value: '1970', text: '1970' },
    { key: '1969', value: '1969', text: '1969' },
    { key: '1968', value: '1968', text: '1968' },
    { key: '1967', value: '1967', text: '1967' },
    { key: '1966', value: '1966', text: '1966' },
    { key: '1965', value: '1965', text: '1965' },
    { key: '1964', value: '1964', text: '1964' },
    { key: '1963', value: '1963', text: '1963' },
    { key: '1962', value: '1962', text: '1962' },
    { key: '1961', value: '1961', text: '1961' },
    { key: '1960', value: '1960', text: '1960' },
    
  ]
  const Cities = [
    { key: 'İstanbul',  value: 'İstanbul' ,  text: 'İstanbul' },
    { key: 'Ankara', value: 'Ankara', text: 'Ankara' },
    { key: 'Adana', value: 'Adana', text: 'Adana' },
    { key: 'Adıyaman', value: 'Adıyaman', text: 'Adıyaman' },
    { key: 'Afyonkarahisar', value: 'Afyonkarahisar', text: 'Afyonkarahisar' },
    { key: 'Ağrı', value: 'Ağrı', text: 'Ağrı' },
    { key: 'Aksaray', value: 'Aksaray', text: 'Aksaray' },
    { key: 'Amasya', value: 'Amasya', text: 'Amasya' },
    { key: 'Antalya', value: 'Antalya', text: 'Antalya' },
    { key: 'Ardahan', value: 'Ardahan', text: 'Ardahan' },
    { key: 'Artvin', value: 'Artvin', text: 'Artvin' },
    { key: 'Aydın', value: 'Aydın', text: 'Aydın' },
    { key: 'Balıkesir', value: 'Balıkesir', text: 'Balıkesir' },
    { key: 'Bartın', value: 'Bartın', text: 'Bartın' },
    { key: 'Batman', value: 'Batman', text: 'Batman' },
    { key: 'Bayburt', value: 'Bayburt', text: 'Bayburt' },
    { key: 'Bilecik', value: 'Bilecik', text: 'Bilecik' },
    { key: 'Bingöl', value: 'Bingöl', text: 'Bingöl' },
    { key: 'Bitlis', value: 'Bitlis', text: 'Bitlis' },
    { key: 'Bolu', value: 'Bolu', text: 'Bolu' },
    { key: 'Burdur', value: 'Burdur', text: 'Burdur' },
    { key: 'Bursa', value: 'Bursa', text: 'Bursa' },
    { key: 'Çanakkale', value: 'Çanakkale', text: 'Çanakkale' },
    { key: 'Çankırı', value: 'Çankırı', text: 'Çankırı' },
    { key: 'Çorum', value: 'Çorum', text: 'Çorum' },
    { key: 'Denizli', value: 'Denizli', text: 'Denizli' },
    { key: 'Diyarbakır', value: 'Diyarbakır', text: 'Diyarbakır' },
    { key: 'Düzce', value: 'Düzce', text: 'Düzce' },
    { key: 'Edirne', value: 'Edirne', text: 'Edirne' },
    { key: 'Elâzığ', value: 'Elâzığ', text: 'Elâzığ' },
    { key: 'Erzincan', value: 'Erzincan', text: 'Erzincan' },
    { key: 'Erzurum', value: 'Erzurum', text: 'Erzurum' },
    { key: 'Eskişehir', value: 'Eskişehir', text: 'Eskişehir' },
    { key: 'Gaziantep', value: 'Gaziantep', text: 'Gaziantep' },
    { key: 'Giresun', value: 'Giresun', text: 'Giresun' },
    { key: 'Gümüşhane', value: 'Gümüşhane', text: 'Gümüşhane' },
    { key: 'Hakkâri', value: 'Hakkâri', text: 'Hakkâri' },
    { key: 'Hatay', value: 'Hatay', text: 'Hatay' },
    { key: 'Iğdır', value: 'Iğdır', text: 'Iğdır' },
    { key: 'Isparta', value: 'Isparta', text: 'Isparta' },
    { key: 'İzmir', value: 'İzmir', text: 'İzmir' },
    { key: 'Kahramanmaraş', value: 'Kahramanmaraş', text: 'Kahramanmaraş' },
    { key: 'Karabük', value: 'Karabük', text: 'Karabük' },
    { key: 'Karaman', value: 'Karaman', text: 'Karaman' },
    { key: 'Kars', value: 'Kars', text: 'Kars' },
    { key: 'Kastamonu', value: 'Kastamonu', text: 'Kastamonu' },
    { key: 'Kayseri', value: 'Kayseri', text: 'Kayseri' },
    { key: 'Kırıkkale', value: 'Kırıkkale', text: 'Kırıkkale' },
    { key: 'Kırklareli', value: 'Kırklareli', text: 'Kırklareli' },
    { key: 'Kırşehir', value: 'Kırşehir', text: 'Kırşehir' },
    { key: 'Kilis', value: 'Kilis', text: 'Kilis' },
    { key: 'Kocaeli', value: 'Kocaeli', text: 'Kocaeli' },
    { key: 'Konya', value: 'Konya', text: 'Konya' },
    { key: 'Kütahya', value: 'Kütahya', text: 'Kütahya' },
    { key: 'Malatya', value: 'Malatya', text: 'Malatya' },
    { key: 'Manisa', value: 'Manisa', text: 'Manisa' },
    { key: 'Mardin', value: 'Mardin', text: 'Mardin' },
    { key: 'Mersin', value: 'Mersin', text: 'Mersin' },
    { key: 'Muğla', value: 'Muğla', text: 'Muğla' },
    { key: 'Muş', value: 'Muş', text: 'Muş' },
    { key: 'Nevşehir', value: 'Nevşehir', text: 'Nevşehir' },
    { key: 'Niğde', value: 'Niğde', text: 'Niğde' },
    { key: 'Ordu', value: 'Ordu', text: 'Ordu' },
    { key: 'Osmaniye', value: 'Osmaniye', text: 'Osmaniye' },
    { key: 'Rize', value: 'Rize', text: 'Rize' },
    { key: 'Sakarya', value: 'Sakarya', text: 'Sakarya' },
    { key: 'Samsun', value: 'Samsun', text: 'Samsun' },
    { key: 'Siirt', value: 'Siirt', text: 'Siirt' },
    { key: 'Sinop', value: 'Sinop', text: 'Sinop' },
    { key: 'Sivas', value: 'Sivas', text: 'Sivas' },
    { key: 'Şanlıurfa', value: 'Şanlıurfa', text: 'Şanlıurfa' },
    { key: 'Şırnak', value: 'Şırnak', text: 'Şırnak' },
    { key: 'Tekirdağ', value: 'Tekirdağ', text: 'Tekirdağ' },
    { key: 'Tokat', value: 'Tokat', text: 'Tokat' },
    { key: 'Trabzon', value: 'Trabzon', text: 'Trabzon' },
    { key: 'Tunceli', value: 'Tunceli', text: 'Tunceli' },
    { key: 'Uşak', value: 'Uşak', text: 'Uşak' },
    { key: 'Van', value: 'Van', text: 'Van' },
    { key: 'Yalova', value: 'Yalova', text: 'Yalova' },
    { key: 'Yozgat', value: 'Yozgat', text: 'Yozgat' },
    { key: 'Zonguldak', value: 'Zonguldak', text: 'Zonguldak' },
  ]
  const Status = [
    { key: 'Hemen',  value: 'Hemen' ,  text: 'Hemen' },
    { key: 'İhbar Sürem Bittiğinde', value: 'İhbar Sürem Bittiğinde', text: 'İhbar Sürem Bittiğinde' },
    { key: '1 Ay Sonra', value: '1 Ay Sonra', text: '1 Ay Sonra' },
    { key: '2 Ay Sonra', value: '2 Ay Sonra', text: '2 Ay Sonra' },
  ]
  const Value = [
    { key: 'Belirtmek İstemiyorum',  value: 'Belirtmek İstemiyorum' ,  text: 'Belirtmek İstemiyorum' },
    { key: '2000-3000',  value: '2000-3000' ,  text: '2000-3000' },
    { key: '3000-4000',  value: '3000-4000' ,  text: '3000-4000' },
    { key: '4000-5000',  value: '4000-5000' ,  text: '4000-5000' },
    { key: '5000-6000',  value: '5000-6000' ,  text: '5000-6000' },
    { key: '6000-7000',  value: '6000-7000' ,  text: '6000-7000' },
    { key: '7000-8000',  value: '7000-8000' ,  text: '7000-8000' },
    { key: '8000-9000',  value: '8000-9000' ,  text: '8000-9000' },
    { key: '10000 ve üzeri',  value: '10000 ve üzeri' ,  text: '10000 ve üzeri' },
  ]
  const EgitimDurumu = [
    { key: 'Yüksek Lisans - Mezun',  value: 'Yüksek Lisans - Mezun' ,  text: 'Yüksek Lisans - Mezun' },
    { key: 'Yüksek Lisans - Öğrenci',  value: 'Yüksek Lisans - Öğrenci' ,  text: 'Yüksek Lisans - Öğrenci' },
    { key: 'Lisans - Öğrenci',  value: 'Lisans - Öğrenci' ,  text: 'Lisans - Öğrenci' },
    { key: 'Lisans - Mezun',  value: 'Lisans - Mezun' ,  text: 'Lisans - Mezun' },
    { key: 'Ön Lisans - Öğrenci',  value: 'Ön Lisans - Öğrenci' ,  text: 'Ön Lisans - Öğrenci' },
    { key: 'Ön Lisans - Mezun',  value: 'Ön Lisans - Mezun' ,  text: 'Ön Lisans - Mezun' },
    { key: 'Lise - Öğrenci', value: 'Lise - Öğrenci', text: 'Lise - Öğrenci' },
    { key: 'Lise - Mezun', value: 'Lise - Mezun', text: 'Lise - Mezun' },
    { key: 'İlkokul', value: 'İlkokul', text: 'İlkokul' },
  ]
  const Depatman=[
    { key: 'SOSYAL İŞLER',  value: '	SOSYAL İŞLER	' ,  text: '	SOSYAL İŞLER	' },
    { key: 'BİRGİ İNPROSES KONTROL',  value: '	BİRGİ İNPROSES KONTROL	' ,  text: '	BİRGİ İNPROSES KONTROL	' },
    { key: 'BİRGİ KALİTE KONTROL',  value: '	BİRGİ KALİTE KONTROL	' ,  text: '	BİRGİ KALİTE KONTROL	' },
    { key: 'KALİTE GRUP BAŞKANLIĞI',  value: '	KALİTE GRUP BAŞKANLIĞI	' ,  text: '	KALİTE GRUP BAŞKANLIĞI	' },
    { key: 'BİRGİ AMPUL İMALAT',  value: '	BİRGİ AMPUL İMALAT	' ,  text: '	BİRGİ AMPUL İMALAT	' },
    { key: 'BİRGİ FLAKON İMALAT',  value: '	BİRGİ FLAKON İMALAT	' ,  text: '	BİRGİ FLAKON İMALAT	' },
    { key: 'BİRGİ İŞLETME YÖNETİMİ',  value: '	BİRGİ İŞLETME YÖNETİMİ	' ,  text: '	BİRGİ İŞLETME YÖNETİMİ	' },
    { key: 'BİRGİ TEKNİK BAKIM',  value: '	BİRGİ TEKNİK BAKIM	' ,  text: '	BİRGİ TEKNİK BAKIM	' },
    { key: 'BİRGİ ÜRETİM',  value: '	BİRGİ ÜRETİM	' ,  text: '	BİRGİ ÜRETİM	' },
    { key: 'BİRGİ ÜRETİM PLANLAMA',  value: '	BİRGİ ÜRETİM PLANLAMA	' ,  text: '	BİRGİ ÜRETİM PLANLAMA	' },
    { key: 'DEPO- DEPO BİRGİ',  value: '	DEPO- DEPO BİRGİ	' ,  text: '	DEPO- DEPO BİRGİ	' },
    { key: 'BİLGİ TEKNOLOJİLERİ MÜDÜRLÜĞÜ',  value: '	BİLGİ TEKNOLOJİLERİ MÜDÜRLÜĞÜ	' ,  text: '	BİLGİ TEKNOLOJİLERİ MÜDÜRLÜĞÜ	' },
    { key: 'FİNANS GRUP YÖNETİMİ',  value: '	FİNANS GRUP YÖNETİMİ	' ,  text: '	FİNANS GRUP YÖNETİMİ	' },
    { key: 'FİNANSAL RAPORLAMA',  value: '	FİNANSAL RAPORLAMA	' ,  text: '	FİNANSAL RAPORLAMA	' },
    { key: 'FİNANS',  value: '	FİNANS	' ,  text: '	FİNANS	' },
    { key: 'MUHASEBE',  value: '	MUHASEBE	' ,  text: '	MUHASEBE	' },
    { key: 'SATIN ALMA',  value: '	SATIN ALMA	' ,  text: '	SATIN ALMA	' },
    { key: 'İCRA KURULU-GENEL MÜDÜRLÜK YÖN',  value: '	İCRA KURULU-GENEL MÜDÜRLÜK YÖN	' ,  text: '	İCRA KURULU-GENEL MÜDÜRLÜK YÖN	' },
    { key: 'İNSAN KAYNAKLARI GRUP YÖNETİMİ',  value: '	İNSAN KAYNAKLARI GRUP YÖNETİMİ	' ,  text: '	İNSAN KAYNAKLARI GRUP YÖNETİMİ	' },
    { key: 'İNSAN KAYNAKLARI',  value: '	İNSAN KAYNAKLARI	' ,  text: '	İNSAN KAYNAKLARI	' },
    { key: 'İNSAN KAYNAKLARI İŞ ORTAKLIĞI	',  value: '	İNSAN KAYNAKLARI İŞ ORTAKLIĞI	' ,  text: '	İNSAN KAYNAKLARI İŞ ORTAKLIĞI	' },
    { key: 'ORGANİZASYONEL GELİŞİM',  value: '	ORGANİZASYONEL GELİŞİM	' ,  text: '	ORGANİZASYONEL GELİŞİM	' },
    { key: 'BORDRO VE ÖZLÜK İŞLERİ',  value: '	BORDRO VE ÖZLÜK İŞLERİ	' ,  text: '	BORDRO VE ÖZLÜK İŞLERİ	' },
    { key: 'İŞ GELİŞTİRME VE SATIŞ YÖNETİM',  value: '	İŞ GELİŞTİRME VE SATIŞ YÖNETİM	' ,  text: '	İŞ GELİŞTİRME VE SATIŞ YÖNETİM	' },
    { key: 'KİLİT MÜŞTERİLER (YURT DIŞI)',  value: '	KİLİT MÜŞTERİLER (YURT DIŞI)	' ,  text: '	KİLİT MÜŞTERİLER (YURT DIŞI)	' },
    { key: 'KİLİT MÜŞTERİLER (YURT İÇİ)',  value: '	KİLİT MÜŞTERİLER (YURT İÇİ)	' ,  text: '	KİLİT MÜŞTERİLER (YURT İÇİ)	' },
    { key: 'ÖZEL MÜŞTERİLER (YURT DIŞI)',  value: '	ÖZEL MÜŞTERİLER (YURT DIŞI)	' ,  text: '	ÖZEL MÜŞTERİLER (YURT DIŞI)	' },
    { key: 'ÖZEL MÜŞTERİLER (YURT İÇİ)',  value: '	ÖZEL MÜŞTERİLER (YURT İÇİ)	' ,  text: '	ÖZEL MÜŞTERİLER (YURT İÇİ)	' },
    { key: 'GMP UYUM',  value: '	GMP UYUM	' ,  text: '	GMP UYUM	' },
    { key: 'KALİFİKASYON/KALİBRASYON',  value: '	KALİFİKASYON/KALİBRASYON	' ,  text: '	KALİFİKASYON/KALİBRASYON	' },
    { key: 'KALİTE GÜVENCE YÖNETİMİ',  value: '	KALİTE GÜVENCE YÖNETİMİ	' ,  text: '	KALİTE GÜVENCE YÖNETİMİ	' },
    { key: 'ÜRÜN SERBEST BIRAKMA',  value: '	ÜRÜN SERBEST BIRAKMA	' ,  text: '	ÜRÜN SERBEST BIRAKMA	' },
    { key: 'VALİDASYON',  value: '	VALİDASYON	' ,  text: '	VALİDASYON	' },
    { key: 'FİZİKSEL KONTROL',  value: '	FİZİKSEL KONTROL	' ,  text: '	FİZİKSEL KONTROL	' },
    { key: 'HAMMADDE ANALİZ',  value: '	HAMMADDE ANALİZ	' ,  text: '	HAMMADDE ANALİZ	' },
    { key: 'İNPROSES KONTROL',  value: '	İNPROSES KONTROL	' ,  text: '	İNPROSES KONTROL	' },
    { key: 'KALİTE KONTROL YÖNETİMİ',  value: '	KALİTE KONTROL YÖNETİMİ	' ,  text: '	KALİTE KONTROL YÖNETİMİ	' },
    { key: 'ÜRÜN ANALİZ',  value: '	ÜRÜN ANALİZ	' ,  text: '	ÜRÜN ANALİZ	' },
    { key: 'KALİTE VE MESUL MÜD.YÖN.',  value: '	KALİTE VE MESUL MÜD.YÖN.	' ,  text: '	KALİTE VE MESUL MÜD.YÖN.	' },
    { key: 'MİKROBİYOLOJİ',  value: '	MİKROBİYOLOJİ	' ,  text: '	MİKROBİYOLOJİ	' },
    { key: 'ŞIRINGA ÜRETİM',  value: '	ŞIRINGA ÜRETİM	' ,  text: '	ŞIRINGA ÜRETİM	' },
    { key: 'DEPO- AMBALAJ DEPO',  value: '	DEPO- AMBALAJ DEPO	' ,  text: '	DEPO- AMBALAJ DEPO	' },
    { key: 'DEPO- DEPO MEFAR',  value: '	DEPO- DEPO MEFAR	' ,  text: '	DEPO- DEPO MEFAR	' },
    { key: 'DEPO- YARI MAMÜL DEPO',  value: '	DEPO- YARI MAMÜL DEPO	' ,  text: '	DEPO- YARI MAMÜL DEPO	' },
    { key: 'DEPO- YÖNETİM',  value: '	DEPO- YÖNETİM	' ,  text: '	DEPO- YÖNETİM	' },
    { key: 'DEPO- HAMMADDE DEPO VE TARTIM',  value: '	DEPO- HAMMADDE DEPO VE TARTIM	' ,  text: '	DEPO- HAMMADDE DEPO VE TARTIM	' },
    { key: 'DEPO-HAMMADDE DEPO VE TARTIM',  value: '	DEPO-HAMMADDE DEPO VE TARTIM	' ,  text: '	DEPO-HAMMADDE DEPO VE TARTIM	' },
    { key: 'MEFAR ÜRETİM MÜDÜRLÜĞÜ',  value: '	MEFAR ÜRETİM MÜDÜRLÜĞÜ	' ,  text: '	MEFAR ÜRETİM MÜDÜRLÜĞÜ	' },
    { key: 'OTOKLAV',  value: '	OTOKLAV	' ,  text: '	OTOKLAV	' },
    { key: 'ÜRETİM SOLÜSYON HAZIRLAMA',  value: '	ÜRETİM SOLÜSYON HAZIRLAMA	' ,  text: '	ÜRETİM SOLÜSYON HAZIRLAMA	' },
    { key: 'ÜRETİM YÖNETİM',  value: '	ÜRETİM YÖNETİM	' ,  text: '	ÜRETİM YÖNETİM	' },
    { key: 'OPERASYON GRUP DİREKTÖRLÜĞÜ',  value: '	OPERASYON GRUP DİREKTÖRLÜĞÜ	' ,  text: '	OPERASYON GRUP DİREKTÖRLÜĞÜ	' },
    { key: 'TEKNİK BAKIM YÖNETİM',  value: '	TEKNİK BAKIM YÖNETİM	' ,  text: '	TEKNİK BAKIM YÖNETİM	' },
    { key: 'MEFAR İŞLETME YÖNETİM',  value: '	MEFAR İŞLETME YÖNETİM	' ,  text: '	MEFAR İŞLETME YÖNETİM	' },
    { key: 'TEKNİK- DESTEK BAKIM',  value: '	TEKNİK- DESTEK BAKIM	' ,  text: '	TEKNİK- DESTEK BAKIM	' },
    { key: 'TEKNİK- ELEKTRİK BAKIM',  value: '	TEKNİK- ELEKTRİK BAKIM	' ,  text: '	TEKNİK- ELEKTRİK BAKIM	' },
    { key: 'TEKNİK- SU SİSTEMİ VE KLİMALAR',  value: '	TEKNİK- SU SİSTEMİ VE KLİMALAR	' ,  text: '	TEKNİK- SU SİSTEMİ VE KLİMALAR	' },
    { key: 'TEKNİK- ÜRETİM MEKANİK',  value: '	TEKNİK- ÜRETİM MEKANİK	' ,  text: '	TEKNİK- ÜRETİM MEKANİK	' },
    { key: 'AMPUL ÜRETİM',  value: '	AMPUL ÜRETİM	' ,  text: '	AMPUL ÜRETİM	' },
    { key: 'BFS ÜRETİM',  value: '	BFS ÜRETİM	' ,  text: '	BFS ÜRETİM	' },
    { key: 'FLAKON VE LİYOFİLİZATÖR',  value: '	FLAKON VE LİYOFİLİZATÖR	' ,  text: '	FLAKON VE LİYOFİLİZATÖR	' },
    { key: 'ETİKETLEME',  value: '	ETİKETLEME	' ,  text: '	ETİKETLEME	' },
    { key: 'OPTİK KONTROL',  value: '	OPTİK KONTROL	' ,  text: '	OPTİK KONTROL	' },
    { key: 'ŞIRINGA',  value: '	ŞIRINGA	' ,  text: '	ŞIRINGA	' },
    { key: 'ÜRETİM PLANLAMA KONTROL',  value: '	ÜRETİM PLANLAMA KONTROL	' ,  text: '	ÜRETİM PLANLAMA KONTROL	' },
    { key: 'ÜRETİM PLANLAMA YÖNETİM',  value: '	ÜRETİM PLANLAMA YÖNETİM	' ,  text: '	ÜRETİM PLANLAMA YÖNETİM	' },
    { key: 'AMBALAJ',  value: '	AMBALAJ	' ,  text: '	AMBALAJ	' },  
    { key: 'OPERASYONEL MÜKEMMELLİK YÖNETİMİ',  value: '	OPERASYONEL MÜKEMMELLİK YÖNETİMİ	' ,  text: '	OPERASYONEL MÜKEMMELLİK YÖNETİMİ	' },
    { key: 'SAĞLIK, EMNİYET VE ÇEVRE YÖNETİMİ',  value: '	SAĞLIK, EMNİYET VE ÇEVRE YÖNETİMİ	' ,  text: '	SAĞLIK, EMNİYET VE ÇEVRE YÖNETİMİ	' },
    { key: 'STRATEJİ & KURUMSAL İLİŞKİLER YÖNETİM',  value: '	STRATEJİ & KURUMSAL İLİŞKİLER YÖNETİM	' ,  text: '	STRATEJİ & KURUMSAL İLİŞKİLER YÖNETİM	' },
    { key: 'BİLİM VE TEKNOLOJİ',  value: '	BİLİM VE TEKNOLOJİ	' ,  text: '	BİLİM VE TEKNOLOJİ	' },
    { key: 'DÖKÜMANTASYON',  value: '	DÖKÜMANTASYON	' ,  text: '	DÖKÜMANTASYON	' },
    { key: 'PROJE',  value: '	PROJE	' ,  text: '	PROJE	' },
    { key: 'STABİLİTE',  value: '	STABİLİTE	' ,  text: '	STABİLİTE	' },
    { key: 'TEKNİK İŞ GELİŞTİRME YÖNETİMİ',  value: '	TEKNİK İŞ GELİŞTİRME YÖNETİMİ	' ,  text: '	TEKNİK İŞ GELİŞTİRME YÖNETİMİ	' },
    { key: 'RUHSATLANDIRMA VE PATENT',  value: '	RUHSATLANDIRMA VE PATENT	' ,  text: '	RUHSATLANDIRMA VE PATENT	' },
    { key: 'TEKNOLOJİ TRANS.VE TEK.HİZMET',  value: '	TEKNOLOJİ TRANS.VE TEK.HİZMET	' ,  text: '	TEKNOLOJİ TRANS.VE TEK.HİZMET	' },
    { key: 'TEKNOLOJİ TRANSFER',  value: '	TEKNOLOJİ TRANSFER	' ,  text: '	TEKNOLOJİ TRANSFER	' },
]

export {Cinsiyet, MedeniDurum, AskelikDurumu, Durum, BasPozisyon, Days, Months, Years, Cities, Status, Value, EgitimDurumu, Depatman, Staj,StajEgitim};