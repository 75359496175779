export const mapData = 
[
  {
    values: {"LY": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"PT": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"ES": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"GB": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"FR": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"IT": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"NL": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"DE": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"CZ": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"PL": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"BA": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"RS": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"ME": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"MK": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"GR": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"TR": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"RO": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"SK": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"UA": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"MD": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"BY": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"LV": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"RU": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"KZ": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"UZ": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"KG": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"TJ": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"TM": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"IQ": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"IL": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"CY": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"GE": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"AM": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"AZ": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"AT": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"HR": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"AL": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"XK": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"NO": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"CA": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"DK": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"HU": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"LT": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"EE": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"FI": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"YE": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"SE": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"BG": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  {
    values: {"SI": 100000 },
    scale: ["#0b4b93", "#0b4b94"],
    normalizeFunction: "polynomial"
  },
  
]
 
  ;