import { ISGClaimTR } from '../components';
import React from 'react';


const ISGTalep = () =>  {
    return(
        <>
            <ISGClaimTR/>
        </>
    )
}

export default ISGTalep;